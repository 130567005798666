import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Heading,
  Text,
  Button,
  Card,
  Divider,
  useToast,
} from '@chakra-ui/react';
import { doc, updateDoc, onSnapshot } from 'firebase/firestore';
import moment from 'moment';

import generateAffiliateAgreement from '../services/generateAffiliateAgreement';
import { db } from '../constants';
import DataContext from '../context/DataContext';
import AppBody from '../components/AppBody';
import SpinnerPage from '../components/SpinnerPage';
import htmlContent from '../components/AffiliateAgreement';

function Contract() {
  const navigate = useNavigate();
  const toast = useToast();
  const [partnerData, setPartnerData] = useState(null);
  const dataContext = useContext(DataContext);

  const { userData } = dataContext;
  const { partnerUid } = userData;

  useEffect(() => {
    if (!partnerUid) return navigate('/profile');
    const unsubscribe = onSnapshot(doc(db, 'partners', partnerUid), doc => {
      setPartnerData(doc.data());
    });
    return unsubscribe;
  }, [partnerUid, navigate]);

  // console.log('loading4')
  // console.log('dataContext', dataContext);
  // console.log(userData);
  // console.log('partnerData', partnerData);

  const generateContract = async () => {
    const partnerRef = doc(db, 'partners', partnerUid);

    // update affiliateAgreementGenerated in app and db
    // setPartnerData(prevState => ({
    //   ...prevState,
    //   affiliateAgreementGenerated: new Date().toString(),
    // }));
    updateDoc(partnerRef, {
      affiliateAgreementGenerated: new Date().toString(),
    });
    const resp = await generateAffiliateAgreement(partnerData.uid);
    // console.log('resp', resp);
    if (!resp.success) {
      console.log('error', resp?.error);
      toast({
        title: 'Contract generation error: please try again later',
        status: 'error',
        isClosable: true,
      });
      // setPartnerData(prevState => ({
      //   ...prevState,
      //   affiliateAgreementGenerated: '',
      // }));
      updateDoc(partnerRef, {
        affiliateAgreementGenerated: '',
      });
    }
  };

  if (!partnerData) {
    return <SpinnerPage />;
  }
  const htmlContentOk = htmlContent(partnerData);
  return (
    <>
      {/* <Header back /> */}
      <AppBody>
        <Heading size="lg" >
          Contract
        </Heading>
        <Divider borderColor="primary.500" borderWidth={1} mb={5}/>
        {!partnerData?.affiliateAgreementExecuted &&
        !partnerData?.affiliateAgreementGenerated ? (
          <>
            <Text>
              To email a contract for signature to{' '}
              {partnerData?.primaryEmail}
            </Text>
            <Button
              // size="sm"
              my={5}
              w="full"
              colorScheme="primary"
              onClick={generateContract}
            >
              Click here
            </Button>
          </>
        ) : (
          <Text>
            Contract link sent for signature:{' '}
            {moment(
              partnerData?.affiliateAgreementGenerated,
              'ddd MMM DD YYYY kk:mm:ss Z ZZ'
            ).format('MMM D, YYYY - h:mma')}
          </Text>
        )}
        {partnerData?.affiliateAgreementExecuted && (
          <Text>
            Contract Executed:{' '}
            {moment(
              partnerData.affiliateAgreementExecuted,
              'ddd MMM DD YYYY kk:mm:ss Z ZZ'
            ).format('MMM D, YYYY - h:mma')} - See email for signed copy
          </Text>
        )}

        <Divider borderColor="primary.500" borderWidth={1} my={3} />
        <Text mt={10}>
          Here are the general terms of the agreement for reference only
        </Text>

        <Card variant={'filled'} p={5}>
          <div dangerouslySetInnerHTML={{ __html: htmlContentOk }} />
        </Card>
      </AppBody>
    </>
  );
}

export default Contract;
